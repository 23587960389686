import * as React from "react";
import TwoCol from "../components/TwoCol";

const LayoutsPage = () => {
  return (
    <main>
      <TwoCol />
    </main>
  );
};

export default LayoutsPage;
